<template>
	<section class="view-dog-questions">
		<questions-resolver type="dog" />
	</section>

	<picture>
		<source media="(min-width: 1024px)" srcset="@/assets/images/view-home/dog-hd.png" />
		<source media="(min-width: 768px)" srcset="@/assets/images/view-home/dog-tablet.png" />
		<img :src="dogMobile" alt="" class="view-dog-questions__dog-img" />
	</picture>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import QuestionsResolver from '@/components/questions/QuestionsResolver.vue';
import { useGetImage } from '@/composables';

export default defineComponent({
	components: { QuestionsResolver },
	setup() {
		const { getHomeImage } = useGetImage();

		return {
			dogMobile: getHomeImage('dog-mobile')
		};
	}
});
</script>

<style lang="scss">
.view-dog-questions {
	@include question-wrapper;

	@include max-phablet {
		.layout-question--date & {
			padding-bottom: 225px;
		}
	}

	@include mobile-landscape {
		padding-bottom: 225px;
	}

	&__dog-img {
		min-width: 59.7rem;
		margin-bottom: -1rem;
		position: fixed;
		right: calc(50% - 54rem);
		bottom: 16.6rem;
		transform: translateX(50%);
		pointer-events: none;

		@include under-hd {
			bottom: 12rem;
		}

		@include max-desktop {
			min-width: 59.7rem * 0.85;
			max-width: 59.7rem * 0.85;
		}

		@include bp(1650) {
			right: calc(50% - 50rem);
		}

		@include max-laptop {
			min-width: 59.7rem * 0.8;
			max-width: 59.7rem * 0.8;
		}

		@include bp(700, $height: true) {
			min-width: 59.7rem * 0.7;
			max-width: 59.7rem * 0.7;
		}

		@include bp(1200) {
			right: calc(50% - 34vw);
		}

		@include max-tablet {
			min-width: 377px;
			max-width: 377px;
			right: calc(50% - 30vw);
			bottom: 105px;
		}

		@include tablet-portrait {
			min-width: 400px;
			max-width: 400px;
			right: calc(50% + 2vw);
			bottom: 145px;
		}

		@include max-phablet {
			min-width: 193px;
			max-width: 193px;
			right: calc(50% + 3vw);
			bottom: 146px;
		}
	}
}
</style>
